$(document).ready(function() {
	
	///////////////////////////////////////////
	/************** star rating **************/
	///////////////////////////////////////////
	$('.rating_stars').raty({ 
		starType: 'i',
		readOnly:true,
		half:true,
		score: function() {
                      return $(this).attr('data-rating');
                     }
	});

});